@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

body {
  --key-color: #7c41c6;
  --key-sub: #600bc9;
  background: black;
}


body * {
  box-sizing: border-box;
  font-family: 'Questrial', sans-serif;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.EmailForm {
  height: 100%;
}

.EmailFormContainer {
  position: relative;
  background: black;
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegisteredUsers {
  color: white;
  padding: 20px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.RegisteredUsers b {
  color: var(--key-color);
}

.SignUpForm {
  min-width: 280px;
}

.--headLine .container {
  z-index: 10;
}

.HeadLine.row {
  z-index: 10;
}


.ThreeBackgroundWrapperContainer.row {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 40px;
  z-index: 0;
}

.StarBackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 0;
}


.navbar-nav {
  text-transform: uppercase;
}

.ThreeBackgroundWrapper {
  padding: 0 !important;
  margin: 0;
  position: relative;
}

.Timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15vw;
  font-variant: tabular-nums;
  color: var(--key-color);
  padding: 5vw;
}

.--CountTimer.container-fluid {
  background: black;
  color: white;
}

.LiveButton {
  position: absolute;
  z-index: 10;
  background: red;
  left: 16px;
  top: 16px;
  font-size: 18px;
  color: white;
  padding: 0 10px;
  border-radius: 8px;
  font-weight: bold;
  animation: blink 0.8s alternate infinite;
}

.--demoVideo.container-fluid {
  padding: 0;
  font-size: 0;
}

#root {
  background: black;
}

.ContentWrapper {
  flex-grow: 1;
}

.Company__intro,
.Company__mission {
  padding: 20px 0;
}

.--USP {
  padding: 40px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.USP {
  background: var(--key-color);
  color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
}

.USP h1,
.USP h4 {
  display: flex;
  justify-content: center;
}

.FooterLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row .FooterLogo {
  height: auto;
  width: 230px;
  padding: 40px 0 0;
}

.Credit {
  padding: 60px 0 0;
}

.TextLink a {
  color: white;
}

.Asimula .btn-primary,
.Asimula .btn-primary:active {
  background: var(--key-color);
  border-color: var(--key-sub);
}

.Asimula .navbar .btn-primary {
  font-weight: bold;
}

.Asimula .btn-primary:hover {
  background: var(--key-sub);
}

.Asimula .btn-primary:hover {
  /* background-color: var(--key-color); */
  border-color: var(--key-color);
  background-color: var(--key-sub);
}

.Asimula .btn-check:focus + .btn-primary,
.Asimula .btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(160 49 253 / 50%);
  background-color: var(--key-color);
  border-color: var(--key-sub);
}

.Asimula .btn-primary:active:focus {
  box-shadow: 0 0 0 0.25rem #673ab7;
}

.--ticketButton {
  margin: 40px 0 0;
}

.Asimula .navbar.bg-dark {
  background-color: var(--key-color) !important;
  background-color: black !important;
}

.Asimula .navbar {
  flex-shrink: 0;
}

.Asimula .navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--key-color) !important;
}

.Asimula .navbar-light .navbar-nav .nav-link.active,
.Asimula .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.3);
}

.navbar-brand .Logo {
  height: 36px;
}

.--headLine {
  padding: 32px 20px 32px;
  overflow: auto;
  background: black;
  min-height: calc(100vh - 44px);
}

.--headLine h5 {
  color: rgba(255, 255, 255, 0.4);
  word-break: break-word;
}

.--headLine h1 b {
  color: var(--key-color);
}

.--headLine h1 {
  text-shadow: 0 1px 0 rgb(0 0 0 / 75%);
  color: white;
  word-break: break-word;
  white-space: break-spaces;
}

.--getTicket.container-fluid {
  background: var(--key-color);
  background: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 32px 0;
  /* aspect-ratio: 16 / 9; */
}

.--getTicket.container-fluid .row h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 100px;
}

.--getTicket.container-fluid .row {
  display: flex;
  justify-content: center;
  align-items: c;
}

.--getTicket.container-fulid {
  background: black;
  color: white;
}

.--extLink {
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 100px; */
  padding: 16px;
}

.--footer .--extLink {
  max-height: 100px;
}

.--extLink svg {
  width: 100%;
  height: 100%;
}

.--extLink.col-sm-3.col-12 svg {
  fill: white;
}

.--extLink.--brand {
  padding: 1vw;
  /* max-height: 17vw; */
  display: flex;
  flex-direction: column;
}

.--extLink.--brand svg {
  width: 100%;
  height: 100%;
  fill: inherit;
  flex-shrink: 0;
  margin: 0 0 8vw;
}

.--extLink.--brand svg path {
  width: 100%;
  height: 100%;
}

.container-fluid.--footer {
  background: black;
  color: white;
  min-height: 30vh;
}

.Asimula .--footer {
  z-index: 1;
  flex-shrink: 0;
}

.Front,
.Company {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.Company,
.Events,
.QuestionMarks {
  background: white;
}
.--headLine h5,
.EmailFormContainer h5,
.SignUpPanel h5 {
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 576px) {
  .--headLine {
    min-height: calc(80vh - 44px);
  }
  .SignUpPanel {
    position: relative;
    background: black;
    z-index: 10;
    color: white;
    padding: 16px;
    font-size: 24px;
  }
  .SignUpPage {
    min-height: 100vh;
    position: relative;
  }
  .BGvideo {
    height: 100vh;
    /* aspect-ratio: 9 / 16; */
    z-index: 0;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    flex-shrink: 0;
  }
  .SignUpPage {
    width: 100%;
    position: relative;
    /* display: flex; */
  }
  .--extLink.--brand svg {
    width: 72px;
    height: 72px;
    margin: 0 0 5vw;
  }

  .--extLink.--brand {
    margin: 0 0 10vw;
  }

  .--getTicket.container-fluid .row h2 {
    padding: 0 0 20px;
  }
  .--headLine h1,
  .EmailFormContainer h1,
  .SignUpPanel h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 576px) {
  .--extLink.--brand svg {
    width: 10vw;
    height: 10vw;
    margin: 0 0 5vw;
  }

  .--headLine h1,
  .EmailFormContainer h1,
  .SignUpPanel h1 {
    font-size: 4rem;
  }

  .SignUpPanel {
    background: black;
    z-index: 10;
    color: white;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }

  .SignUpPanel h1 {
    text-align: center;
  }
  /* .BGvideo1 {
    height: 100vh;
    aspect-ratio: 16 / 9;
    z-index: 0;
    display: flex;
    justify-content: left;
    align-items: flex-end;
  }   */
  .BGvideo {
    height: 100vh;
    aspect-ratio: 9 / 16;
    z-index: 0;
    display: flex;
    justify-content: left;
    align-items: flex-end;
  }
  .SignUpPage {
    position: relative;
    display: flex;
  }
}
@media (min-width: 768px) {
  .--extLink.--brand svg {
    width: 12vw;
    height: 12vw;
    margin: 0 0 4vw;
  }

}


@media (min-width: 992px) {

  .ThreeBackground {
    /* position: absolute; */
    right: 0 !important;
    bottom: 0 !important;
    z-index: 0 !important;
    width: 100% !important;
    height: 100% !important;
    pointer-events: none !important;
    position: relative !important;
    aspect-ratio: 8 / 10 !important;
  }

  .--extLink.--brand svg {
    width: 10vw;
    height: 10vw;
  }
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
  .navbar-expand-lg .navbar-nav {
    gap: 10px;
  }
  .TextLink {
    text-align: left;
  }
  .Credit.row {
    flex-direction: column-reverse;
  }

  .--headLine h1,
  .EmailFormContainer h1,
  .SignUpPanel h1 {
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .--extLink.--brand svg {
    width: 8vw;
    height: 8vw;
  }
}

@media (min-width: 1440px) {
  .--extLink.--brand svg {
    width: 120px;
    height: 120px;
  }

  .--getTicket.container-fluid .row h2 {
    padding: 0 0 200px;
  }

  .--extLink.--brand {
    max-height: 120px;
  }
}

@media screen and (orientation: portrait) {
  @media (min-width: 1024px) {
    /* iPad Pro*/
    .ThreeBackgroundWrapper {
      width: 100% !important;
      margin-top: -160px !important;
    }
    .LiveButton {
      top: 200px;
    }
    .HeadLine__column {
      width: 100% !important;
    }
  }
  @media (min-width: 768px) {
    /* iPad*/
    .ThreeBackgroundWrapper {
      width: 100% !important;
      margin-top: -160px !important;
    }

    .LiveButton {
      top: 200px;
    }
    .HeadLine__column {
      width: 100% !important;
    }
  }
}
@keyframes blink {
  0% {
    background: red;
  }
  100% {
    background: rgb(179, 0, 0);
  }
}